export function formatTimestamp(timestamp) {
    // 创建 Date 对象
    const date = new Date(Number(timestamp));
    // 获取月份和日期
    const month = date.getMonth() + 1; // getMonth() 返回 0-11, 所以需要 +1
    const day = date.getDate();

    // 获取小时和分钟
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // 格式化小时和分钟为两位数
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // 返回格式化后的字符串
    return `${formattedHours}:${formattedMinutes}`;
}

/*判断是否真的登陆*/
export function getToken() {
    if (localStorage.getItem('TOKEN') && localStorage.getItem('TOKEN') !== 'null') {
        return true
    } else {
        return false
    }
}

export function parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        } else if (typeof time === 'string') {
            time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000

    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        )
    }
}

/*游戏列表图片解析*/
export function dealImgFn(resp) {
    resp.data.list.map((item) => {
        // 处理特殊情况，如 The Catfather 需要特殊的图片文件名
        if (item.gameName.toLowerCase() === 'the catfather') {
            // 特定文件名的处理
            item.path = require('@/assets/gameImg/' + 'The Catfather Part II.webp');
        } else {
            // 默认情况下根据 gameName 动态加载图片
            try {
                // 使用 require 动态加载图片路径，注意 .webp 结尾
                item.path = require(`@/assets/gameImg/${item.gameName}.webp`);
            } catch (e) {
                try {  //第一次匹配失败 转为小写
                    // console.log('--------------------')
                    item.path = require(`@/assets/gameImg/${item.gameName.toLowerCase()}.webp`)
                } catch (err) {
                    // console.error(`无法加载图片: ${item.gameName}.webp`);
                    // 如果图片不存在，设置默认图片
                    item.path = require('@/assets/gameImg/default.png');
                }
            }
        }
    });
}





